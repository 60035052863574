import "./menuPC.css";
import regis from "../img/Group1345.png" 
import login from "../img/Group2345.png"
const Menu = () => {
  return (
    <div className="menupc">
      <div className="menupc_container">        
          <li>
            <a href="#">Trang chủ</a>
          </li>
          <li>
            <a href="#">Diễn đàn</a>
          </li>
          
            <img className="but" style={{width:'15%'}} src={regis}/>
    
            <img className="but" style={{width:'15%'}} src={login}/>
        
      </div>
    </div>
  );
};

export default Menu;
