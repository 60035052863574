import "./serverSelect.css";
import frameWrapper from "../img/f1-1.png";
import buttonSelect from "../img/bnt1-1.png";

import frameWrapper1 from "../img/fr11.png";
import vectorZ from "../img/Vector123.png";
import btn3 from "../img/Group213.png";
import btn4 from "../img/group3333.png";
const ServerSelect = () => {
  return (
    <div className="frame-1"> 
    <div className="container-server"> 
      <img className="frame-wrapper" src={frameWrapper}></img>
       <img className="frame-wrapper2"  src={frameWrapper1} />  
      <div className="server">
        <div className="sv-select">
          <img className="btn-sv" src={buttonSelect} alt="" />
          <span className="text-sv-select">Chọn máy chủ để tham gia</span>
          <img className="vectorZ" src={vectorZ} />
        </div>
        <div className="server-list">
          <div className="czx" style={{ width: "30%" }}>
            <a className="zxz">
              <img src={btn4} />
            </a> 
            <a className="zxz2">
              <img src={btn3} />
            </a>
          </div>
          <div className="czx" style={{ width: "30%" }}>
            <a className="zxz3">
              <img src={btn3} />
            </a>
            <a className="zxz4">
              <img src={btn4} />
            </a>
          </div>
        </div>
      </div>
    </div> 
    </div>
  );
};

export default ServerSelect;
