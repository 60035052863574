import './footer.css';
import license from '../img/image-104.png';
import base from '../img/group-33.png';
import social from '../img/frame-9.svg';
import payment from '../img/frame-8.png';
import guarantee from '../img/image-106.png';
import langBtn from '../img/lang-btn.png';
import lang from '../img/Frame7.png'

const Footer = () => {
    return ( 
     
        <div className="footer"> 
        <div className='container5'> 
        <div className="group-27">
          <div className="frame-9 col-4">
            <div className="text-wrapper-12">Giấy Phép Hợp Tác</div>
                    <img className="image-9" src={license} />
          </div>
          <div className="frame-10">
            <div className="text-wrapper-12">Gia Nhập Nền Tảng</div>               
              <img className="gianhapnentang" src={base} alt="" />                            
          </div>
          <div className="frame-11">
            <div className="text-wrapper-12">Mạng Xã Hội</div>
            <img className="frame-12" src={social} />
          </div>
        </div>
        <div className="frame-7 col-12">
          <div className="text-wrapper-12">Phương Thức Thanh Toán</div>
          <div className="frame-8">
            <img className="image" src={payment} />                
          </div>
            </div>
            
        <div className="frame-13-14">
          <div className="frame-13">
          <div className="text-wrapper-13">
              Trách nhiệm uy tín &amp; an toàn
            </div>
                    <img className="image-13" src={guarantee} />
          </div>
          <div className="frame-14">
             <img className="image-14" src={lang} />      
          </div>
        </div>
        <div className="group-32 col-12">
          <div className="group-34">
            <div className="text-wrapper-18">Về Chúng Tôi</div>
            <div className="text-wrapper-18">Chơi Có Trách Nhiệm</div>
            <div className="text-wrapper-18">Quyền Riêng Tư</div>
            <div className="text-wrapper-18">Điều Khoản Và Điều Kiện</div>
            <div className="text-wrapper-18">Liên Hệ Chúng Tôi</div>
            <div className="text-wrapper-18">Hợp Tác</div>
            
          </div>
        </div>
        <div className="banquyen-wrapper">
          <div className="text-wrapper-18">© 2024 Mọi Bản Quyền Thuộc Về Shbet</div>
        </div>
      </div> 
      </div>
    )
}

export default Footer;