import "./App.css";
import Banner from "./components/banner/Banner";
import ServerSelect from "./components/severSelect/ServerSelect";
import Boss from "./components/boss/Boss";
import Subject from "./components/subject/Subject";
import Footer from "./components/footer/Footer";
import Menu from "./components/menu/Menu";
import Feedback from "./components/feedback/Feedback";
import BackToTop from "./components/backToTop/BackToTop";
import MenuPC from "./components/menuPC/MenuPC";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import React, { useState, useEffect } from "react";
import Loading from "./components/loading/Loading";
import MobileMenu from "./components/menu/MenuMobile";
import ScreenWidth from "./components/ScreenWidth";

function App() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchData().then(response => {
      setData(response);
      setLoading(false);
    });
  }, []);

  const fetchData = async () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve('Data fetched');
      }, 3000);
    });
  }

  return (
    <div className="App">
      {loading ? (
        <Loading />
      ) : (
        <>  
          <div className="container2"> 
          <Banner /> 
          <MenuPC /> 
          <MobileMenu/>
          <Menu />
          <BackToTop />
          <ServerSelect />
          <Boss />
          <Subject /> 
     
          <Footer /> 
          </div>
        </> 

      )}
    </div>
  );
}

export default App;
