import React from "react";

import bannerImg from "../img/banner-1.png";
import logo from "../img/logo.png";
import "./banner.css";
import background from "../img/background.mp4";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import logo1 from "../img/BANNER1.png" 

const Banner = () => {

  return (
    <div className="banner-wrapper">
      <div className="frame-0"> 
      <img className="banner-mb" src={bannerImg} /> 
        <img className="banner-pc" src={logo1} />  
        <div className="logo-wrapper">
          <img className="logo" src={logo} />
        </div>
      </div>
    </div>
  );
};

export default Banner;
