import React, { useEffect, useState, useRef } from "react";
import "./subject.css";
import fakeImg from "../img/k1-1.png";
import lineVector from "../img/vector-9.png";
import viewMore from "../img/view-more.png";
import Feedback from "../feedback/Feedback";

const Subject = () => {
  const [visibleItems, setVisibleItems] = useState(4);
  const videoRefs = useRef([]);
  const videoData = [
    {
      id: 1,
      title: "500AE - Triển khai cuộc thi cho nhà đầu tư",
      date: "1 ngày trước",
    },
    {
      id: 2,
      title: "500AE - Triển khai cuộc thi cho nhà đầu tư",
      date: "1 ngày trước",
    },
    {
      id: 3,
      title: "500AE - Triển khai cuộc thi cho nhà đầu tư",
      date: "1 ngày trước",
    },
    {
      id: 4,
      title: "500AE - Triển khai cuộc thi cho nhà đầu tư",
      date: "1 ngày trước",
    },
    {
      id: 5,
      title: "500AE - Triển khai cuộc thi cho nhà đầu tư",
      date: "1 ngày trước",
    },
    {
      id: 6,
      title: "500AE - Triển khai cuộc thi cho nhà đầu tư",
      date: "1 ngày trước",
    },
    {
      id: 7,
      title: "500AE - Triển khai cuộc thi cho nhà đầu tư",
      date: "1 ngày trước",
    },
    {
      id: 8,
      title: "500AE - Triển khai cuộc thi cho nhà đầu tư",
      date: "1 ngày trước",
    },
    // Thêm các video khác vào đây
  ];
  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  };

  const createObserver = () => {
    return new IntersectionObserver(
      debounce((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      }, 100), // debounce với thời gian 100ms
      { threshold: 0.25 }
    );
  };

  useEffect(() => {
    const observer = createObserver();

    videoRefs.current.forEach((video) => {
      if (video) observer.observe(video);
    });

    return () => {
      videoRefs.current.forEach((video) => {
        if (video) observer.unobserve(video);
      });
    };
  }, []);

  const handleViewMoreClick = () => {
    setVisibleItems(8); // Hiển thị 8 phần tử

    // Tạo lại observer để quan sát các phần tử mới
    const observer = createObserver();
    videoRefs.current.slice(4).forEach((video) => {
      if (video) observer.observe(video);
    });
  };

  const handleShowLessClick = () => {
    setVisibleItems(4); // Thu gọn lại về 4 phần tử
    const firstVideoItem = document.querySelector(".video-item");
    if (firstVideoItem) {
      firstVideoItem.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="frame-3">
      <div className="ch">CHỦ ĐỀ</div>

      <div className="group-11">
        <div className="container3">
          <div className="overlap-group-4">
            <div className="text-wrapper-7">Chủ đề đặc biệt</div>
            <div className="text-wrapper-7">Video giải trí đặc sắc</div>
            <div className="text-wrapper-7">Video thiện nguyện</div>
            <div className="glow-effect1"></div>
          </div>
        </div>
      </div> 
      <div className="container4"> 
      <div className="wrapper-video-feedback"> 
        
        <div className="framePc">
          <div className="container-vid-fed">
            <div className="wrapper-video">
              <div className="text-video">VIDEO</div>
              <div className="overlap-6">
                <div className="group-14"> 
                  <div > 
                  <div
                    className={`group-15 ${
                      visibleItems === 8 ? "expanded" : ""
                    }`}
                  >
                    {videoData.slice(0, visibleItems).map((video, index) => (
                      <div
                        key={video.id}
                        className="video-item"
                        ref={(el) => (videoRefs.current[index] = el)}
                      >
                        <img
                          className="k"
                          src={fakeImg}
                          alt={`Video ${video.id}`}
                        />
                        <div className="element-tri-n-khai-cu">
                          {video.title}
                          <br />
                        </div>
                        <div className="text-wrapper-10">{video.date}</div>
                      </div>
                    ))}

                    <div className="glow-effect2"></div>
                  </div>
                  {visibleItems === 4 ? (
                    <img
                      className="view-more"
                      src={viewMore}
                      alt="View More"
                      onClick={handleViewMoreClick}
                    />
                  ) : (
                    <button
                      className="collapse-button"
                      onClick={handleShowLessClick}
                    >
                      Thu gọn
                    </button>
                  )} 
                   </div>
                  <img className="line-vector" src={lineVector} />
                  <p className="lorem-ipsum-dolor">
                    LOREM IPSUM DOLOR SIT AMET, CONSECTETUR ADIPISCING ELIT, SED
                    DO EIUSMOD TEMPOR INCIDIDUNT UT LABORE ET DOLORE MAGNA
                    ALIQUA. QUIS IPSUM SUSPENDISSE ULTRICES GRAVIDA. RISUS
                    COMMODO VIVERRA MAECENAS ACCUMSAN LACUS VEL FACILISIS. LOREM
                    IPSUM DOLOR SIT AMET, CONSECTETUR ADIPISCING ELIT, SED DO
                    EIUSMOD TEMPOR INCIDIDUNT UT LABORE ET DOLORE MAGNA ALIQUA.
                    QUIS IPSUM SUSPENDISSE ULTRICES GRAVIDA. RISUS COMMODO
                    VIVERRA MAECENAS ACCUMSAN LACUS VEL FACILISIS. LOREM IPSUM
                    DOLOR SIT AMET, CONSECTETUR ADIPISCING ELIT, SED DO EIUSMOD
                    TEMPOR INCIDIDUNT UT LABORE ET DOLORE MAGNA ALIQUA. QUIS
                    IPSUM SUSPENDISSE ULTRICES GRAVIDA. RISUS COMMODO VIVERRA
                    MAECENAS ACCUMSAN LACUS VEL FACILISIS. LOREM IPSUM DOLOR SIT
                    AMET, CONSECTETUR ADIPISCING ELIT, SED DO EIUSMOD TEMPOR
                    INCIDIDUNT UT LABORE ET DOLORE MAGNA ALIQUA. QUIS IPSUM
                    SUSPENDISSE ULTRICES GRAVIDA. RISUS COMMODO VIVERRA MAECENAS
                    ACCUMSAN LACUS VEL FACILISIS.
                  </p>
                </div>
              </div>
            </div>
            <Feedback />
          </div>
        </div>
      </div>
    </div> 
    </div>
  );
};

export default Subject;
