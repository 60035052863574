import React from 'react';
import './loading.css'; // Để thêm CSS cho loading spinner

const Loading = () => {
  return (
    <div className="loading-overlay">
      <div className="loading-spinner"></div>
      <div className="text-loading">Loading...</div>
    </div>
  );
};

export default Loading;