import React, { useEffect, useState, useRef } from "react";
import "./boss.css";
import group5 from "../img/Group-5.png";
import group6 from "../img/Group6.png";
import group7 from "../img/Group7.png";
import Carousel from "./Carousel";

const Boss = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const captions = [
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  ];

  const numbersRef = useRef([]);

  const runCounter = () => {
    numbersRef.current.forEach((number) => {
      if (number) {
        const targetValue = +number.getAttribute("data-value");
        const increment = targetValue / 100;
        let currentValue = 0;

        const counter = setInterval(() => {
          currentValue += increment;
          if (currentValue >= targetValue) {
            currentValue = targetValue;
            clearInterval(counter);
          }
          number.textContent = `+${Math.floor(currentValue)}`;
        }, 20);
      }
    });
  };

  useEffect(() => {
    runCounter();

    const counterInterval = setInterval(() => {
      runCounter();
    }, 3000);

    return () => {
      clearInterval(counterInterval);
    };
  }, []);

  return (
    <div className="container-data-boss">
      <div className="wrapper-data-boss">
        <div className="right-side">
          <div className="boss-carousel">
            <Carousel onSlideChange={(index) => setCurrentIndex(index)} />
          </div>
          <p className="caption-text">{captions[currentIndex]}</p>
        </div>
        <div className="left-side">
          <div className="data-title">
            <span className="text-title1">NHỮNG CON SỐ </span>
            <span className="text-title2"> ĐÁNG TỰ HÀO</span>
          </div>
          <div className="data-body">
            <div className="group-data col-6">
              <div
                className="number"
                data-value="50"
                ref={(el) => (numbersRef.current[0] = el)}
              ></div>
              <img className="vectorc" src={group5} alt="Group 5" />
              <p className="desc-text"> Chuyên gia sau 6 tháng </p>
            </div>
            <div className="group-data col-6">
              <div
                className="number"
                data-value="100"
                ref={(el) => (numbersRef.current[1] = el)}
              ></div>
              <img className="vectorc" src={group6} alt="Group 6" /> 
              <p className="desc-text"> Chương trình đầu tư thành công </p>
            </div>
            <div className="group-data col-6">
              <div
                className="number"
                data-value="500"
                ref={(el) => (numbersRef.current[2] = el)}
              ></div>
              <img className="vectorc" src={group7} alt="Group 7" /> 
              <p className="desc-text"> Nhà đầu tư thành công </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Boss;
