import "./menu.css";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Draggable from "react-draggable";

const Menu = () => {
  useEffect(() => {
    let toggle = document.querySelector(".banner-toggle");
    let menu = document.querySelector(".banner-menu");
    toggle.onclick = function () {
      menu.classList.toggle("active");
    };
  }, []);

  // useEffect(() => {
  //     dragElement(document.getElementById("mydiv"));

  //     function dragElement(elmnt) {
  //         var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
  //         if (document.getElementById(elmnt.id + "header")) {
  //             // if present, the header is where you move the DIV from:
  //             document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
  //         } else {
  //             // otherwise, move the DIV from anywhere inside the DIV:
  //             elmnt.onmousedown = dragMouseDown;
  //         }

  //         function dragMouseDown(e) {
  //             e = e || window.event;
  //             e.preventDefault();
  //             // get the mouse cursor position at startup:
  //             pos3 = e.clientX;
  //             pos4 = e.clientY;
  //             document.onmouseup = closeDragElement;
  //             // call a function whenever the cursor moves:
  //             document.onmousemove = elementDrag;
  //         }

  //         function elementDrag(e) {
  //             e = e || window.event;
  //             e.preventDefault();
  //             // calculate the new cursor position:
  //             pos1 = pos3 - e.clientX;
  //             pos2 = pos4 - e.clientY;
  //             pos3 = e.clientX;
  //             pos4 = e.clientY;
  //             // set the element's new position:

  //             elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
  //             elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
  //         }

  //         function closeDragElement() {
  //             // stop moving when mouse button is released:
  //             document.onmouseup = null;
  //             document.onmousemove = null;
  //         }
  //     }
  // }, []);

  return (
    <div className="banner-wraper" id="mydiv">
      <div className="banner-menu">
        <div className="banner-toggle">
          <FontAwesomeIcon icon={faPlus} className="icon-menu" />
        </div>
        <ul>
          <li style={{ "--i": 0 }}>
            <a href="#">
              <FontAwesomeIcon icon={faPlus} className="icon-menu" />
            </a>
          </li>
          <li style={{ "--i": 1 }}>
            <a href="#">
              <FontAwesomeIcon icon={faPlus} className="icon-menu" />
            </a>
          </li>
          <li style={{ "--i": 2 }}>
            <a href="#">
              <FontAwesomeIcon icon={faPlus} className="icon-menu" />
            </a>
          </li>
          <li style={{ "--i": 3 }}>
            <a href="#">
              <FontAwesomeIcon icon={faPlus} className="icon-menu" />
            </a>
          </li>
          <li style={{ "--i": 4 }}>
            <a href="#">
              <FontAwesomeIcon icon={faPlus} className="icon-menu" />
            </a>
          </li>
          <li style={{ "--i": 5 }}>
            <a href="#">
              <FontAwesomeIcon icon={faPlus} className="icon-menu" />
            </a>
          </li>
          <li style={{ "--i": 6 }}>
            <a href="#">
              <FontAwesomeIcon icon={faPlus} className="icon-menu" />
            </a>
          </li>
          <li style={{ "--i": 7 }}>
            <a href="#">
              <FontAwesomeIcon icon={faPlus} className="icon-menu" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Menu;
