import "./feedback.css";
import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const Feedback = () => {
  useEffect(() => {
    if (window.innerWidth >= 1224) {
      return;
    }

    let items = document.querySelectorAll(".slider .item");
    let next = document.getElementById("next");
    let prev = document.getElementById("prev");
    let indicators = document.querySelectorAll(".indicator");

    let active = 3;
    function loadShow() {
      let stt = 0;
      items[active].style.transform = `none`;
      items[active].style.zIndex = items.length;
      items[active].style.filter = "none";
      items[active].style.opacity = 1;
      for (var i = active + 1; i < items.length; i++) {
        stt++;
        items[i].style.transform = `translateX(${120 * stt}px) scale(${
          1 - 0.2 * stt
        })`;
        items[i].style.zIndex = stt;
        items[i].style.filter = "blur(5px)";
        items[i].style.opacity = stt > 2 ? 0 : 0.6;
      }

      stt = 0;
      for (var i = active - 1; i >= 0; i--) {
        stt++;
        items[i].style.transform = `translateX(${-120 * stt}px) scale(${
          1 - 0.2 * stt
        })`;
        items[i].style.zIndex = stt;
        items[i].style.filter = "blur(5px)";
        items[i].style.opacity = stt > 2 ? 0 : 0.6;
      }

      indicators.forEach((indicator, index) => {
        if (index === active) {
          indicator.classList.add("active");
        } else {
          indicator.classList.remove("active");
        }
      });
    }
    loadShow();

    next.onclick = function () {
      active = active + 1 < items.length ? active + 1 : active;
      loadShow();
    };

    prev.onclick = function () {
      active = active - 1 >= 0 ? active - 1 : active;
      loadShow();
    };
  }, []);

  return (
    <div className="feedback-wrapper">
      <div className="text-feedback">FEEDBACK</div>
      <div className="slider">
        <div className="item">
          <div className="title-feedback">
            <FontAwesomeIcon icon={faUser} className="icon-feedback" />
            <span>
              <div>Nguyễn Văn A</div>
              <div>Vip 10</div>
            </span>
          </div>
          <p className="desc-fb">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis 
          </p>
        </div>
        <div className="item">
          <div className="title-feedback">
            <FontAwesomeIcon icon={faUser} className="icon-feedback" />
            <span >
              <div>Nguyễn Văn A</div>
              <div>Vip 10</div>
            </span>
          </div>
          <p className="desc-fb">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis 
          </p>
        </div>
        <div className="item">
          <div className="title-feedback">
            <FontAwesomeIcon icon={faUser} className="icon-feedback" />
            <span>
              <div>Nguyễn Văn A</div>
              <div>Vip 10</div>
            </span>
          </div>
          <p className="desc-fb">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis 
          </p>
        </div>
        <div className="item">
          <div className="title-feedback">
            <FontAwesomeIcon icon={faUser} className="icon-feedback" />
            <span>
              <div>Nguyễn Văn A</div>
              <div>Vip 10</div>
            </span>
          </div>
          <p className="desc-fb">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis 
          </p>
        </div>
        <div className="item">
          <div className="title-feedback">
            <FontAwesomeIcon icon={faUser} className="icon-feedback" />
            <span>
              <div>Nguyễn Văn A</div>
              <div>Vip 10</div>
            </span>
          </div>
          <p className="desc-fb">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis </p>
        </div>
        <div className="item">
          <div className="title-feedback">
            <FontAwesomeIcon icon={faUser} className="icon-feedback" />
            <span>
              <div>Nguyễn Văn A</div>
              <div>Vip 10</div>
            </span>
          </div>
          <p className="desc-fb">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis 
          </p>
        </div>
        <div className="other">
          <button id="next">&gt;</button>
          <button id="prev">&lt;</button>
          <div class="carousel-indicators">
            <span class="indicator"></span>
            <span class="indicator"></span>
            <span class="indicator"></span>
            <span class="indicator active"></span>
            <span class="indicator"></span>
            <span class="indicator"></span>
            <span class="indicator"></span>
          </div>
        </div>
      </div>
      <div className="text-feedback-2">FEEDBACK</div>
      <p className="lorem-2">
        LOREM IPSUM DOLOR SIT AMET, CONSECTETUR ADIPISCING ELIT, SED DO EIUSMOD
        TEMPOR INCIDIDUNT UT LABORE ET DOLORE MAGNA ALIQUA. QUIS IPSUM
        SUSPENDISSE ULTRICES GRAVIDA. RISUS COMMODO VIVERRA MAECENAS ACCUMSAN
        LACUS VEL FACILISIS. LOREM IPSUM DOLOR SIT AMET, CONSECTETUR ADIPISCING
        ELIT, SED DO EIUSMOD TEMPOR INCIDIDUNT UT LABORE ET DOLORE MAGNA ALIQUA.
        QUIS IPSUM SUSPENDISSE ULTRICES GRAVIDA. RISUS COMMODO VIVERRA MAECENAS
        ACCUMSAN LACUS VEL FACILISIS. LOREM IPSUM DOLOR SIT AMET, CONSECTETUR
        ADIPISCING ELIT, SED DO EIUSMOD TEMPOR INCIDIDUNT UT LABORE ET DOLORE
        MAGNA ALIQUA. QUIS IPSUM SUSPENDISSE ULTRICES GRAVIDA. RISUS COMMODO
        VIVERRA MAECENAS ACCUMSAN LACUS VEL FACILISIS. LOREM IPSUM DOLOR SIT
        AMET, CONSECTETUR ADIPISCING ELIT, SED DO EIUSMOD TEMPOR INCIDIDUNT UT
        LABORE ET DOLORE MAGNA ALIQUA. QUIS IPSUM SUSPENDISSE ULTRICES GRAVIDA.
        RISUS COMMODO VIVERRA MAECENAS ACCUMSAN LACUS VEL FACILISIS.
      </p>
    </div>
  );
};

export default Feedback;
