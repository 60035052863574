

import React, { useState, useEffect, useRef } from 'react';
import "./MenuMb.css"; 
import menumb from "../img/Frame24.png"
import vector from "../img/Vector.png"


const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target) && isOpen) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <>
      {!isOpen && (
        <button className="menu-button" onClick={toggleMenu}><img className='img-menumb' src={menumb}/></button>
      )}
      
      <div ref={sidebarRef} className={`sidebar ${isOpen ? 'open' : ''}`}>
        <div className="sidebar-content">
          <div className="logo">
            <h2><img className='logombbb' src={vector}/></h2>
            
          </div> 
          <div className="login-buttons">
          <button className='dki'> Đăng Ký </button>
        <button className='dnhap'>Đăng nhập</button>
       </div>
          <ul className="menu-items">
            <li><a href="#" onClick={toggleMenu}>Trang Chủ</a></li>
            <li><a href="#" onClick={toggleMenu}>Diễn đàn</a></li>
          </ul>
        
        </div>
      </div>
    </>
  );
};

export default MobileMenu;

