import React, { useState, useEffect, useRef } from "react";
import "./carousel.css";
import boss1 from "../img/boss1.png";
import boss2 from "../img/boss2.png";
import boss3 from "../img/boss3.png";
import boss4 from "../img/boss4.png";
import nextbtn from "../img/Arrow1.png";
import leftbtn from "../img/Arrow2.png";

const Carousel = ({ onSlideChange }) => {
  const [carouselData, setCarouselData] = useState([
    { id: "1", src: boss1 },
    { id: "2", src: boss2 },
    { id: "3", src: boss3 },
    { id: "4", src: boss4 },
  ]);

  const [playState, setPlayState] = useState(null);
  const carouselContainerRef = useRef(null);

  useEffect(() => {
    // Tự động chạy slider khi component được render
    const interval = setInterval(next, 1500);
    setPlayState(interval);

    // Cleanup interval khi component bị unmount
    return () => clearInterval(interval);
  }, []);

  const previous = () => {
    setCarouselData((prevData) => {
      const newData = [...prevData];
      const lastItem = newData.pop();
      newData.unshift(lastItem);
      return newData;
    });

    onSlideChange(0); // Cập nhật chỉ số khi chuyển slide trước
  };

  const next = () => {
    setCarouselData((prevData) => {
      const newData = [...prevData];
      const firstItem = newData.shift();
      newData.push(firstItem);
      return newData;
    });

    onSlideChange(1); // Cập nhật chỉ số khi chuyển slide sau
  };

  const stopSlider = () => {
    if (playState) {
      clearInterval(playState);
      setPlayState(null); // Dừng slider
    }
  };

  return (
    <div className="carousel">
      <div className="carousel-container" ref={carouselContainerRef}>
        {carouselData.slice(0, 4).map((item, index) => (
          <img
            key={item.id}
            className={`carousel-item carousel-item-${index}`}
            src={item.src}
            alt={`Slide ${item.id}`}
            loading="lazy"
            onClick={stopSlider} // Dừng slider khi người dùng click vào hình ảnh
          />
        ))}
      </div>
      <div className="carousel-controls">
        <button className="control-button" onClick={previous} data-name="previous">
          <img className="img-control" src={leftbtn} />
        </button>
        <button className="control-button" onClick={next} data-name="next">
          <img className="img-control" src={nextbtn} />
        </button>
      </div>
    </div>
  );
};

export default Carousel;
